import React from 'react'
import "./Footer.css"
import Wave from "../../img/wave.png"
import Insta from "@iconscout/react-unicons/icons/uil-instagram"
import Linkedin from "@iconscout/react-unicons/icons/uil-linkedin"
import Github from "@iconscout/react-unicons/icons/uil-github"


const Footer = () => {
  return (
    <div className='footer'>
      <img src={Wave} alt="" style={{width: "100%"}}/>
      <div className="f-content">
        <span>muhammadwaleedahsan43@gmail.com</span>
        <div className="f-icons">
           <a href="https://github.com/Waleed0260"> <Insta color='white' size='3rem'/></a>
           <a href="https://www.linkedin.com/in/muhammad-waleed-1b044a227/" target="_blank"><Linkedin color='white' size='3rem'/></a>
           <a href=""><Github color='white' size='3rem'/></a>
        </div>
      </div>
    </div>
  )
}

export default Footer
