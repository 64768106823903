import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Toggle from "./Components/Navbar/Toggle/Toggle";
import Intro from "./Components/Intro/Intro";
import Footer from "./Components/Footer/Footer";
import Services from "./Components/Services/Services"

import Experience from "./Components/Experience/Experience";
import Testimonials from "./Components/Testimonials/Testimonials";

// import Service from "./Components/Service/Service";
import { useContext } from "react";
import { themeContext } from "./Context";


import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <Router>
    <div
      className="App"
      style={{
        background: darkMode ? "black" : "",
        color: darkMode ? "white" : "",
      }}
    >
      <>
      <div className='n-wrapper'>
        <div className="n-left">
            <div className="n-name">Andrew</div>
            <Toggle/>
        </div>
        <div className="n-right">
            <div className="n-list">
                <ul>
                    <li><Link to="/" className="link-1">Home</Link></li>
                    <li><Link to="/Services" className="link-3">Services</Link></li>

                    {/* <li><Link to="/Portfolio"  className="link-2">Portfolio</Link></li> */}
                    <li><Link to="/Experiences" className="link-4">Experiences</Link></li>
                    <li><Link to="/Testimonials" className="link-5">Testimonials</Link></li>
                </ul>
            </div>
            <div className="button n-button">
                Contact
            </div>   
        </div>
    </div>     
    
    
    
     <Routes>
        <Route exact path='/' element={<Intro/>}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/Experiences" element={<Experience />}/>
        <Route path="/Testimonials" element={<Testimonials />}/>

        {/* <Route path="/Portfolio" element={<Portfolio />}/> */}

      </Routes>
      <Footer />
      </>
    </div>
    </Router>
  );
}

export default App;