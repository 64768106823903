import React from 'react'
import './Intro.css'
import Github from "../../img/github.png"
import Linkedin from "../../img/linkedin.png"
import instagram from "../../img/instagram (1).png"
import Vector1 from "../../img/Vector1.png"
import Vector2 from "../../img/Vector2.png"
import boy from "../../img/boy.png"
import thumbup from "../../img/thumbup.png"
import Crown from "../../img/crown.png"
import glassesimoji from "../../img/glassesimoji.png"
import Floatingdiv from '../FloatingDiv/Floatingdiv'


import Services from "../Services/Services";
import Experience from "../Experience/Experience";
import Works from "../Works/Works";
import Testimonial from "../Testimonials/Testimonials";
import Contact from "../Contact/Contact";




import { motion } from "framer-motion"


const Intro = () => {
  const transition = {duration : 2, type : 'spring'}
  return (
    <div>
    <div className='intro'>
      <div className="i-left">
        <div className="i-name">
            <span>Hy I am </span>
            <span> Muhammad Waleed</span>
            <span>Front end developer with high level of experience in web designing and development, producing the quality work</span>
        </div>
        <div className="button i-button">  
Hire me</div>
        <div className="i-icons">
         <a href="https://github.com/Waleed0260" target="_blank"> <img src={Github} alt="" /></a>
         <a href="https://www.linkedin.com/in/muhammad-waleed-1b044a227/" target="_blank"><img src={Linkedin} alt="" /></a>
         <a href=""> <img src={instagram} alt="" /></a>
        </div>
      </div>

      <div className="i-right">
      <img src={Vector1} alt="" />
      <img src={Vector2} alt="" />
      <img src={boy} alt="" />
      <motion.img
      initial={{left: '-36%'}} 
      whileInView={{left: '-24%'}}
      transition={transition}
      src={glassesimoji} alt=""/>



      <motion.div
      initial={{top: '-4%', left:'74%'}}
      whileInView={{left: '68%'}}
      transition={transition}
      style={{top: '-4%', left:'68%'}}
      className="floating-div">
        <Floatingdiv image={Crown} txt1='Web' txt2='Developer'/>
      </motion.div>




      <motion.div
      initial={{top: '18rem', left: '9rem'}}
      whileInView={{top: '18rem', left: '0rem'}}
      transition={transition}
      style={{top: '18rem', left: '0rem'}}
      className="floating-div">
        <Floatingdiv image={thumbup} txt1='Best Design' txt2='Award'/>
      </motion.div>
      {/* blur divs */}
      <div className="blur" style={{background: "rgb(238 210 255)"}}></div>
      <div className="blur" style={{top: '17rem', width: '21rem', height: '11rem', background: '#C1F5FF'}}></div>
      </div>
    </div>


<Services />
<Experience />
<Works />
<Testimonial />
<Contact />

</div>
  )
}

export default Intro
